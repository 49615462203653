<template>
    <v-row no-gutters>
        <v-col cols="12">
            <!-- hierarchical navigation -->
            <v-row justify="start" class="mt-2 mx-4">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-caption text-start">
                    <router-link :to="{ name: 'user-dashboard' }">Dashboard</router-link> &gt;
                    <router-link :to="{ name: 'user-account-list' }">Accounts</router-link>
                </p>
                </v-col>
            </v-row>

            <v-row justify="center" class="pt-5">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <v-card elevation="2" class="my-6">
                        <v-app-bar color="indigo" dark flat dense>
                            <v-app-bar-title>Create new authentication realm</v-app-bar-title>
                            <v-spacer/>
                            <!-- <v-btn icon @click="openCreateAccountDialog">
                                <font-awesome-icon :icon="['fas', 'plus']" style="font-size: 20px;" fixed-width/>
                            </v-btn> -->
                        </v-app-bar>
                        <v-form @submit.prevent="onSubmitForm" @keyup.enter.prevent="onSubmitForm" class="mx-5 mt-5">
                            <v-text-field
                                ref="newRealmNameInput"
                                v-model="newRealmName"
                                label="Display Name"
                                color="indigo"
                                hint="The name to display prominently to users"
                                type="text"
                                outlined
                                dense
                            >
                            </v-text-field>
                            <v-text-field
                                class="mt-4"
                                ref="newRealmAliasInput"
                                v-model="newRealmAlias"
                                label="Alias (code name)"
                                color="indigo"
                                hint="A meaningful alias to use when referencing this realm in code or settings"
                                type="text"
                                outlined
                                dense
                                @focus="newRealmAliasAuto = false"
                            >
                            </v-text-field>
                            <v-radio-group label="Deployment mode" mandatory v-model="newRealmDeploymentMode">
                                <v-radio value="production">
                                    <template #label>
                                        Production (requires secure connection with HTTPS)
                                    </template>
                                </v-radio>
                                <v-radio value="development">
                                    <template #label>
                                        Development (allows insecure connection with HTTP)
                                    </template>
                                </v-radio>
                            </v-radio-group>
                            <v-radio-group label="User alias mode" mandatory v-model="newRealmUserAliasMode">
                                <v-radio value="email">
                                    <template #label>
                                        Email (username is an email address)
                                    </template>
                                </v-radio>
                                <v-radio value="pseudonym">
                                    <template #label>
                                        Pseudonym (username is a pseudonym)
                                    </template>
                                </v-radio>
                            </v-radio-group>
                        </v-form>
                        <v-card-actions>
                            <v-spacer/>
                            <v-btn elevation="4" class="indigo white--text" @click="createRealm" :disabled="!isFormComplete">
                                <font-awesome-icon :icon="['fas', 'check']" fixed-width/>
                                <span class="ml-2">Create</span>
                            </v-btn>
                            <v-spacer/>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<style scoped>

</style>

<script>
import { mapState } from 'vuex';
import { isValidName, compact } from '@/sdk/input';

export default {
    data: () => ({
        createRealmForm: null,
        newRealmName: null,
        newRealmNameRules: [
            (v) => !!v || 'Display name is required',
            (v) => !v || isValidName(compact(v)) || 'Realm name is required',
        ],
        newRealmAlias: null,
        newRealmAliasRules: [
            (v) => !!v || 'Alias is required',
            (v) => !v || isValidName(compact(v)) || 'Realm alias is required', // TODO: change validation to isValidRealmAlias
        ],
        newRealmAliasAuto: true,
        newRealmDeploymentMode: 'production', // TODO: use constants shared with server
        newRealmUserAliasMode: 'email', // TODO: use constants shared with server
        submitTimestamp: null,
    }),
    computed: {
        ...mapState({
            isAuthenticatedReady: (state) => state.isReady,
            session: (state) => state.session,
        }),
        isAuthenticated() {
            return this.session.isAuthenticated;
        },
        isFormComplete() {
            const name = this.newRealmName && isValidName(compact(this.newRealmName));
            const alias = this.newRealmAlias && isValidName(compact(this.newRealmAlias));
            return name && alias && this.newRealmDeploymentMode && this.newRealmUserAliasMode;
        },
    },
    watch: {
        newRealmName(newValue, oldValue) {
            if (newValue && newValue !== oldValue && this.newRealmAliasAuto) {
                this.newRealmAlias = compact(newValue);
            }
        },
    },
    methods: {
        async createRealm() {
            try {
                this.$store.commit('loading', { createRealm: true });
                console.log('createRealm');
                const request = {
                    display_name: this.newRealmName,
                    alias: this.newRealmAlias,
                    deployment_mode: this.newRealmDeploymentMode,
                    user_alias_mode: this.newRealmUserAliasMode,
                };
                const response = await this.$client.account(this.$route.params.accountId).realm.create(request);
                console.log('createRealm response: %o', response);
                const { isCreated, id, error } = response;
                if (isCreated && id) {
                    this.$router.replace({ name: 'realm-dashboard', params: { accountId: this.$route.params.accountId, realmId: id } });
                } else if (error) {
                    console.error(`failed to create realm: ${JSON.stringify(error)}`);
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to create realm' });
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to create realm' });
                }
            } catch (err) {
                console.log('create realm failed', err);
                if (err.response?.status === 409) {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'There is already a realm with the same alias', message: 'Change the alias and try again' });
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to create realm' });
                }
            } finally {
                this.$store.commit('loading', { createRealm: false });
            }
        },
        onSubmitForm() {
            if (Number.isInteger(this.submitTimestamp) && this.submitTimestamp + 500 > Date.now()) {
                return;
            }
            this.submitTimestamp = Date.now();
            // if (this.$refs.createRealmFormRef.validate()) {
            //     this.createRealm();
            // }
            this.createRealm();
        },

        async addRealm() {
            const realm = { id: this.addRealmId, label: this.addRealmChoices.find((item) => item.value === this.addRealmId).text };
            this.list.push(realm);
            this.$emit('added-realm', realm);
            this.displayCreateRealm = false;
            this.addRealmId = null;
        },
        activate(ref) {
            const inputRef = Array.isArray(this.$refs[ref]) ? this.$refs[ref][0] : this.$refs[ref];
            if (inputRef) {
                // more than one way to do it:
                // 1. inputRef.focus();
                // 2. const inputElement = inputRef.$el.querySelector('input'); inputElement.focus();
                // 3. const inputElement = inputRef.$el.querySelector('input'); document.getElementById(inputElement.id).focus()
                inputRef.focus();
            }
        },
    },
    mounted() {
        this.$nextTick(() => {
            setTimeout(() => { this.activate('newRealmNameInput'); }, 1);
        });
    },
};
</script>
